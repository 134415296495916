.round-info {
  width: 100%;
  margin-bottom: calc(12px * var(--device-pixel-ratio));
  margin-top: calc(6px * var(--device-pixel-ratio));
  transition: all .4s;
  &-board {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 calc(29px * var(--device-pixel-ratio));
  }
  & .message {
    display: block;
    font: calc(15px * var(--device-pixel-ratio))/calc(18px * var(--device-pixel-ratio)) "SF Pro Text Medium";
    letter-spacing: calc(0.6px * var(--device-pixel-ratio));
    width: 100%;
    text-align: center;
    color: #FFFFFF;
    text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) #000000;
    z-index: 1;
    position: relative;
    height: calc(20px * var(--device-pixel-ratio));
  }
  &.threeD {
    margin-bottom: calc(-16px * var(--device-pixel-ratio));
    & .message {
      bottom: -57px;
    }
  }
}
.smallPc {
  .round-info {
    width: calc(100% * var(--device-pixel-ratio));
    margin: 0;
    
    &-board {
      padding: 0;
      & .timer {
        transform: translateX(-50%) scale(0.71) translateY(calc(19px * var(--device-pixel-ratio)));
        bottom: calc(13px * var(--device-pixel-ratio));
      }
  
    }
    & .message {
      font-size: calc(10px * var(--device-pixel-ratio));
      line-height: calc(12px * var(--device-pixel-ratio));
      position: relative;
      top: -6px;
    }
    &.threeD {
      .message {
        top: 3px;
      }
      .round-info-board {
        bottom: 0;
        & .timer {
          bottom: calc(4px * var(--device-pixel-ratio));
          top: unset;
        }
      }
    }
  }
}

.tab {
  .round-info {
    position: absolute;
    order: 2;
    height: 20px;
    top: 186px;
    width: 100%;
    margin-bottom: 0;
    margin-top: 0;
    transition: unset;
    &-board {
      & .timer {
        transform: scale(0.7) translateX(calc(-75% * var(--device-pixel-ratio))) translateY(calc(11px * var(--device-pixel-ratio)));
        bottom: calc(-42px * var(--device-pixel-ratio));
        top: unset;
      }
    }
    & .message {
      font-size: calc(10px * var(--device-pixel-ratio));
      letter-spacing: calc(0.4px * var(--device-pixel-ratio));
      position: relative;
      bottom: 0;
      margin-top: calc(41px * var(--device-pixel-ratio));
    }
    &.threeD {
      & .round-info-board {
        & .timer {
          bottom: calc(-37px * var(--device-pixel-ratio));
        }
      }
      & .message {
        margin-top: calc(36px * var(--device-pixel-ratio));
      }
      margin-bottom: calc(-132px * var(--device-pixel-ratio));
    }
  }
}

@media only screen and (max-width: 540px) {
  .round-info {
    margin: auto;
    margin-bottom: calc(0px * var(--device-pixel-ratio));
    width: 100%;
    position: absolute;
    top: -13.4vh;
    &.threeD {
      margin-top: 0;
      margin-bottom: calc(0px * var(--device-pixel-ratio));
      & .message {
        bottom: -20px;
      }
    }
    &-board {
      justify-content: center;
      & .timer {
        left: unset;
        bottom: 14px;
        transform: scale(0.67) translateX(1%) translateY(37%);
        top: unset;
        & .timer-message {
          font-size: 15px;
          line-height: 22px;
          text-shadow: 2px 2px 2px #000000;
        }
        & .timer-ticker {
          width: 48px;
          height: 48px;
          svg {
            width:  51px;
            height: 51px;
            transform: translate(-1px, -2px);
            circle {
              stroke-width: 1px !important;
            }
          }
        }
        & .text {
          letter-spacing: 0.92px;
          text-shadow: 2px 2px 2px #000000;
          font-size: 14px;
          line-height: 16px;
        }
        &:before {
          width: 96px;
          height: 96px;
        }
      }
    }
    & .message {
      font-size: 2.66vw;
      line-height: 3.2vw;
      font-family: "SF Pro Text Medium";
      letter-spacing: 0.4px;
      color: rgba(255, 255, 255, 1);
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
      position: absolute;
      bottom: -20px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100vw;
    }
  }
}