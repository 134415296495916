.bet-area, .user-actions-area {
    width: 100%;
    height: 100%;
    perspective: calc(929px * var(--device-pixel-ratio));
    display: flex;
    justify-content: center;
}
.bet-area {
    pointer-events: none;
    .board-body {
        .board {
            display: flex;
            gap: 40px;
            align-items: center;
            justify-content: center;
            flex-wrap: nowrap;

            &-left, &-right, &-middle {
                & svg {
                    opacity: 0.4;
                }
            }
            &-left, &-right {
                svg {
                    width: 108px;
                }
            }
            &-middle {
                svg {
                    width: 128px;
                }
            }
        }
    }
    
    &.enabled-main-bet-area {
        pointer-events: all;
        .board-body {
            .board {
                &-middle {
                    & svg {
                        transition: fill 0.3s ease-in-out;
                        cursor: pointer;
                        opacity: 1;
                    }
                }
                &-left, &-right {
                    pointer-events: none;
                }
                &-left, &-middle { 
                    svg {
                        &:hover {
                            g {
                                g {
                                    fill: rgba(3, 76, 140, 0.7);
                                }
                            }
                        }
                        &:active {
                            g {
                                g {
                                    fill: rgba(3, 76, 140, 1);
                                }
                            }
                        }
                    }
                }

                &-right {
                    svg {
                        &:hover {
                            & > g {
                                & > g:nth-child(2) {
                                    fill: rgba(3, 76, 140, 0.7);
                                }
                            }
                        }
                        &:active {
                            & > g {
                                & > g:nth-child(2) {
                                    fill: rgba(3, 76, 140, 1);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.enabled-side-bet-area {
        .board-body {
            .board {
                &-left, &-right {
                    pointer-events: all;
                    
                    & svg {
                        transition: fill 0.3s ease-in-out;
                        cursor: pointer;
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.smallPc {
    .bet-area {
        .board-body {
            .board {
                gap: 32px;

                &-left, &-right {
                    svg {
                        width: 98px;
                    }
                }
                &-middle {
                    svg {
                        width: 118px;
                    }
                }
            }
        }
        &.threeD {
            & .bet-body {
               & .middle-area {
                //    height: calc(164px * var(--device-pixel-ratio));
               }
            }
        }
    }
}

.tab {
    .bet-area {
        .board-body {
            .board {
                gap: 33px;
                &-left, &-right {
                    svg {
                        width: 98px;
                    }
                }
                &-middle {
                    svg {
                        width: 118px;
                    }
                }
            }
        }
        &.threeD {
            & .bet-body {
               & .middle-area {
                //    height: calc(164px * var(--device-pixel-ratio));
               }
            }
        }
    }
}

@media only screen and (max-width: 540px) {
    .bet-area {
        .board-body {
            .board {
                gap: 5.333vw;
                &-left, &-right {
                    svg {
                        width: 20.8vw;
                    }
                }
                &-middle {
                    svg {
                        width: 26.133vw;
                    }
                }
            }
        }
        &.threeD {
            & .board-body {
                transform: unset;
            }
        }
    }
}