.timer {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    left: 50%;
    bottom: calc(5px * var(--device-pixel-ratio));
    transform: translateX(-52%);
    opacity: 0;
    transition: opacity .5s;
    top: calc(-90px * var(--device-pixel-ratio));
    &:before {
        content: "";
        opacity: 1;
        filter: blur(24px);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        width: calc(96px * var(--device-pixel-ratio));
        height: calc(96px * var(--device-pixel-ratio));
        z-index: 0;
        margin: auto;
    }
    &.white {
        &:before {
            background: transparent $stream-time-white-bg-color 0 0 no-repeat padding-box;
        }
        & .timer-message {
            color: $stream-time-text-color;
        }
        & .timer-ticker {
            border-color: $stream-time-ticker-border-color;
        }
    }
    &.green {
        &:before {
            background: transparent $stream-time-green-bg-color 0 0 no-repeat padding-box;
        }
        & .timer-message {
            color: $stream-time-green-text-color;
        }
        & .timer-ticker {
            border-color: $stream-time-green-border-color;
            & svg {
            & circle {
                stroke: $stream-time-green-text-color;
            }
            }
            & .text {
            color: $stream-time-green-text-color;
            }
        }
    }
    &.red {
        &:before {
            background: transparent $stream-time-red-bg-color 0 0 no-repeat padding-box;
        }
        & .timer-message {
            color: $stream-time-red-text-color;
        }
        & .timer-ticker {
            border-color: $stream-time-red-border-color;
            & svg {
            & circle {
                stroke: $stream-time-red-text-color;
            }
            }
            & .text {
            color: $stream-time-red-text-color;
            }
        }
    }
    &.yellow {
        &:before {
            background: transparent $stream-time-yellow-bg-color 0 0 no-repeat padding-box;
        }
        & .timer-message {
            color: $stream-time-yellow-text-color;
        }
        & .timer-ticker {
            border-color: $stream-time-yellow-border-color;
            & svg {
            & circle {
                stroke: $stream-time-yellow-text-color;
            }
            }
            & .text {
            color: $stream-time-yellow-text-color;
            }
        }
    }
    & .text {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        font: 900 calc(23px * var(--device-pixel-ratio))/calc(28px * var(--device-pixel-ratio)) "SF Pro Text Light";
        letter-spacing: calc(0.92px * var(--device-pixel-ratio));
        color: $stream-time-text-color;
        text-shadow: 0 calc(2px * var(--device-pixel-ratio)) calc(4px * var(--device-pixel-ratio)) $stream-time-text-shadow-color;
        z-index: 1;
    }
    & .timer-message {
        font: calc(14px * var(--device-pixel-ratio))/calc(22px * var(--device-pixel-ratio)) "SF Pro Text Medium";
        text-shadow: calc(2px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) $stream-message-text-shadow-color;
        width: 100%;
        text-align: center;
        z-index: 1;
        letter-spacing: calc(0px * var(--device-pixel-ratio));
        margin-bottom: calc(4px * var(--device-pixel-ratio));
    }
    & .timer-ticker {
        width: calc(50px * var(--device-pixel-ratio));
        height: calc(50px * var(--device-pixel-ratio));
        background: #00000080 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 0px calc(20px * var(--device-pixel-ratio)) #000000;
        border-style: solid;
        border-radius: 50%;
        position: relative;
        z-index: 1;
        border-width: 0.1em;
        & svg {
            position: absolute;
            width:  calc(54px * var(--device-pixel-ratio));
            height: calc(54px * var(--device-pixel-ratio));
            transform: translate(calc(-1px * var(--device-pixel-ratio)), calc(-2px * var(--device-pixel-ratio)));
            & circle {
                fill: transparent;
                stroke: #ffffff;
                stroke-width: calc(1px * var(--device-pixel-ratio)) !important;
                stroke-dashoffset: 0%;
                stroke-dasharray: 290%;
            }
        }
    }
    &.show-time-ticker {
        opacity: 1;
    }
}
