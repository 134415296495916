.history {
  width: 100%;
  .modal-header {
    padding: calc(16px * var(--device-pixel-ratio)) calc(20px * var(--device-pixel-ratio)) calc(13px * var(--device-pixel-ratio)) calc(20px * var(--device-pixel-ratio));
  }
  &.showFilter {
    .history-table {
      margin: calc(16px * var(--device-pixel-ratio)) calc(20px * var(--device-pixel-ratio)) 0 calc(20px * var(--device-pixel-ratio));
      &-body {
        height: calc((1080px - 386px) * var(--device-pixel-ratio));
      }
    }
  }
  & .history-table {
    margin: 0 calc(20px * var(--device-pixel-ratio)) 0 calc(20px * var(--device-pixel-ratio));
    & table {
      width: 100%;
      border-collapse: collapse;
      & thead {
        & tr {
          & th {
            font: calc(17px * var(--device-pixel-ratio))/calc(20px * var(--device-pixel-ratio)) "SF Pro Text Bold";
            letter-spacing: calc(0.68px * var(--device-pixel-ratio));
            color: $history-table-head-text-color;
            text-align: left;
            padding-bottom: calc(24px * var(--device-pixel-ratio));
            &:nth-child(1) {
              width: 30%;
            }
            &:nth-child(2) {
              width: 30%;
            }
            &:nth-child(3) {
              width: 20%;
            }
          }
        }
      }
    }
    &-body {
      overflow: auto;
      height: calc((1080px - 260px) * var(--device-pixel-ratio));
      & table {
        & tbody {
          scrollbar-width: none;
          &::-webkit-scrollbar-thumb {
            -webkit-box-shadow: inset 0 0 calc(6px * var(--device-pixel-ratio)) rgba(0,0,0,0);
            background-color: transparent;

          }
          &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            -webkit-box-shadow: inset 0 0 calc(6px * var(--device-pixel-ratio)) rgba(0,0,0,.1);
          }

          & tr {
            color: $history-table-body-row-color;
            cursor: pointer;

            &.sum {
              border-bottom: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
              margin-bottom: calc(6px * var(--device-pixel-ratio));
              padding-bottom: calc(6px * var(--device-pixel-ratio));
            }
            & td {
              font: calc(14px * var(--device-pixel-ratio))/calc(13px * var(--device-pixel-ratio)) "SF Pro Text Medium";
              letter-spacing: calc(0.56px * var(--device-pixel-ratio));
              padding: calc(10px * var(--device-pixel-ratio)) 0 calc(10px * var(--device-pixel-ratio)) ;
              &:nth-child(1) {
                width: 30%;
              }
              &:nth-child(2) {
                width: 30%;
              }
              &:nth-child(3) {
                width: 20%;
              }
            }
          }
        }
      }
    }
    &-no-data {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-bottom: 1px solid rgba(135, 135, 148, 0.5);
      & .icon {
        width: calc(120px * var(--device-pixel-ratio));
        height: calc(74px * var(--device-pixel-ratio));
        margin: auto;
      }
      & .title {
        text-align: center;
        font: bold  calc(20px * var(--device-pixel-ratio))/ calc(22px * var(--device-pixel-ratio)) "SF Pro Text Bold";
        letter-spacing: 0;
        color: #878794;
        padding: calc(16px * var(--device-pixel-ratio)) 0 calc(12px * var(--device-pixel-ratio)) 0;
      }
    }
  }
}

.smallPc {
  .history {
    & .history-table {
      &-body {
        height: calc((800px - 246px) * var(--device-pixel-ratio));
      }
    }

    &.showFilter {
      .history-table {
        &-body {
          height: calc((800px - 390px) * var(--device-pixel-ratio));
        }
      }
    }
  }
}

.tab {
  .history {
    & .modal-header {
      padding: calc(15px * var(--device-pixel-ratio)) calc(16px * var(--device-pixel-ratio)) calc(11px * var(--device-pixel-ratio)) calc(16px * var(--device-pixel-ratio));
    }
    & .history-table {
      &-body {
        height: calc((1024px - 285px) * var(--device-pixel-ratio));
      }
    }

    &.showFilter {
      .history-table {
        &-body {
          height: calc((1024px - 400px) * var(--device-pixel-ratio));
        }
      }
    }
  }
}

@media only screen and (max-width: 540px) {
  .history {
    & .history-table {
      &-body {
        height: calc(100vh - 13.19vh - 137px);
      }
      &-no-data {
        & .icon {
          width: calc(80px * var(--device-pixel-ratio));
          height: calc(50px * var(--device-pixel-ratio));
          margin: auto;
        }
        & .title {
          text-align: center;
          font: bold  calc(15px * var(--device-pixel-ratio))/ calc(22px * var(--device-pixel-ratio)) "SF Pro Text Bold" !important;
          letter-spacing: 0;
          color: #878794;
          padding: calc(12px * var(--device-pixel-ratio)) 0 calc(8px * var(--device-pixel-ratio)) 0;
        }
      }

      & table {
        & thead {
          & tr {
            & th {
              letter-spacing: 0.68px;
              font-size: 2.93vw;
              padding-bottom: 16px;
              &:nth-child(1) {
                width: 40%;
              }

              &:nth-child(2) {
                width: 35%;
              }
              &:nth-child(3) {
                width: 25%;
              }
            }
          }
        }

        & tbody {
          & tr {
            & td {
              font-size: 2.6vw;
              line-height: 16px;
              letter-spacing: 0.56px;
              white-space: nowrap;
              &:nth-child(1) {
                width: 40%;
              }

              &:nth-child(2) {
                width: 35%;
              }
              &:nth-child(3) {
                width: 25%;
              }
            }
          }
        }
      }

      .loadingHistory {
        margin: 5px 0;

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    &.showFilter {
      .history-table {
        &-body {
          height: calc(100vh - 13.19vh - 250px);
        }
      }
    }
  }
}