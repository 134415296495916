.drop-down {
  border-radius: calc(20px * var(--device-pixel-ratio));
  color: $drop-down-color;
  min-width: calc(106px * var(--device-pixel-ratio));
  position: relative;
  background: rgba(29, 27, 45, 0.8) 0 0 no-repeat padding-box;
  border: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
  z-index: 1;

  &.open {
    border-radius: calc(20px * var(--device-pixel-ratio)) calc(20px * var(--device-pixel-ratio)) 0 0;
    border-bottom: 0;
    &:before{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(2px);
      z-index: -1;
      border-radius: calc(21px * var(--device-pixel-ratio));
      background-color: $datePicker-bg-color;
    }
    & .drop-down-title {
      padding: calc(10px * var(--device-pixel-ratio)) calc(12px * var(--device-pixel-ratio))  0 calc(16px * var(--device-pixel-ratio));
      & svg {
        transform: rotate(90deg);
      }
    }
  }
  &-title {
    padding: calc(10px * var(--device-pixel-ratio)) calc(16px * var(--device-pixel-ratio));
    font: 300 calc(14px * var(--device-pixel-ratio))/calc(16px * var(--device-pixel-ratio)) "SF Pro Text Light";
    height: calc(40px * var(--device-pixel-ratio));
    letter-spacing: calc(0.56px * var(--device-pixel-ratio));
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
    & .icon {
      width: calc(24px * var(--device-pixel-ratio));
      height: calc(24px * var(--device-pixel-ratio));
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      &:hover {
        background: #54687E 0 0 no-repeat padding-box;
        opacity: 0.4;
      }
      &:active {
        background: #54687E 0 0 no-repeat padding-box;
        opacity: 0.9;
      }
      & svg {
        width: calc(21px * var(--device-pixel-ratio));
        height: calc(21px * var(--device-pixel-ratio));
        //transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
      }
    }


  }
  &-option {
    list-style: none;
    position: absolute;
    min-width: calc(106px * var(--device-pixel-ratio));
    background: rgba(29, 27, 45, 0.8) 0 0 no-repeat padding-box;
    border: 1px solid rgba(135, 135, 148, 0.5);
    border-radius: 0 0 calc(20px * var(--device-pixel-ratio)) calc(20px * var(--device-pixel-ratio));
    border-top: 0;
    left: calc(-1px * var(--device-pixel-ratio));
    top: 99%;
    &-list {
      &-item {
        position: relative;
        z-index: 1;
        padding: calc(10px * var(--device-pixel-ratio)) calc(12px * var(--device-pixel-ratio));
        text-align: left;
        font: 300 calc(14px * var(--device-pixel-ratio))/calc(16px * var(--device-pixel-ratio)) "SF Pro Text Light";
        letter-spacing: calc(0.56px * var(--device-pixel-ratio));
        color:$drop-down-color;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-transform: capitalize;
        & svg {
          width: calc(16px * var(--device-pixel-ratio));
          height: calc(16px * var(--device-pixel-ratio));
          margin-left: calc(8px * var(--device-pixel-ratio));
          pointer-events: none;
        }
        &:last-child {
          border-bottom-right-radius: calc(20px * var(--device-pixel-ratio));
          border-bottom-left-radius: calc(20px * var(--device-pixel-ratio));
        }
        &:hover {
          background: $drop-down-item-bg-hover-color 0 0 no-repeat padding-box;
          cursor: pointer;
        }
        &:active {
          background: rgba(84, 104, 126, 0.9) 0 0 no-repeat padding-box;
        }
      }
    }
  }
}

.smallPc {
  .drop-down {
    min-width: calc(104px * var(--device-pixel-ratio));
  }
}

@media only screen and (max-width: 540px) {
  .drop-down {
    border-radius: 21px;
    width: 19.2vw;
    min-width: unset;

    &-title {
      padding: 8px 12px;
      font: 300 2.66vw/12px "SF Pro Text Light";
      letter-spacing: 0.56px;
      border-radius: 14px;
      height: 28px;

      & .icon {
        width: 12px;
        height: 12px;

        & svg {
          width: 12px;
          height: 12px;
        }
      }
    }

    &-option {
      margin-top: 1px;
      min-width: unset;
      width: 19.2vw;

      &-list-item {
        padding: 10px 12px;
        font: 300 2.66vw/12px "SF Pro Text Light";
        letter-spacing: 0.56px;

        & svg {
          width: 25px;
          height: 25px;
          margin-left: 8px;
        }

        &:last-child {
          border-bottom-right-radius: 14px;
          border-bottom-left-radius: 14px;
        }
      }
    }

    &.open &-option {
      &::before {
        border-bottom-left-radius: 14px;
        border-left: 1px solid rgba(135, 135, 148, 0.5);
      }
    }
  }
}