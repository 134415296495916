.menu-list {
  list-style: none;
  padding-bottom: calc(20px * var(--device-pixel-ratio));
  & .menu-list-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $rightMenu-text-color;
    text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) $rightMenu-text-shadow-color;
    font-family: "SF Pro Text Medium";
    font-size: calc(14px * var(--device-pixel-ratio));
    height: calc(36px * var(--device-pixel-ratio));
    letter-spacing: calc(0.56px * var(--device-pixel-ratio));
    padding: 0 calc(40px * var(--device-pixel-ratio));
    text-transform: capitalize;
    &:hover {
      background-color: rgba(84, 104, 126, 0.4);
    }
    &:not(:first-child){
      margin-top: calc(14px * var(--device-pixel-ratio));
    }
    & > svg {
      width: calc(28px * var(--device-pixel-ratio));
      height: calc(28px * var(--device-pixel-ratio));
      margin-right: calc(12px * var(--device-pixel-ratio));
    }
    &:hover {
      color: rgba(255, 204, 0, 1);
      & svg {
        & #Vector-2 {
          fill: rgba(255, 204, 0, 1);
        }
      }
    }
  }
}