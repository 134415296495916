.tooltip-wrapper {
  &:active > .tooltip-content {
    opacity: 0;
    visibility: hidden;
  }
}

.tooltip-content {
  position: absolute;
  z-index: 10;
  animation-duration: 0.9s;
  animation-name: animateToolTip;
  animation-timing-function: linear;
  background: $tooltip-bg-color 0 0 no-repeat padding-box;
  box-shadow: 0 calc(2px * var(--device-pixel-ratio)) calc(8px * var(--device-pixel-ratio)) $tooltip-shadow-color;
  border-radius: calc(2px * var(--device-pixel-ratio));
  padding: calc(7px * var(--device-pixel-ratio)) calc(8px * var(--device-pixel-ratio));
  text-align: center;
  font: calc(14px * var(--device-pixel-ratio))/calc(22px * var(--device-pixel-ratio)) "SF Pro Text Medium";
  letter-spacing: 0px;
  color: $tooltip-text-color;
  width: max-content;
  text-transform: capitalize;
  & .arrow {
    position: absolute;
    top: calc(-10px * var(--device-pixel-ratio));
    right: calc(50% - 5px * var(--device-pixel-ratio));
    z-index: 1;
    width: calc(10px * var(--device-pixel-ratio));
    height: calc(10px * var(--device-pixel-ratio));
    &:before {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      content: "";
      border-style: solid;
      border-width: 0 calc(6px * var(--device-pixel-ratio)) calc(10px * var(--device-pixel-ratio)) calc(6px * var(--device-pixel-ratio));
      border-color: transparent transparent $tooltip-arrow-color transparent;
    }
  }
  &.left {
    & .arrow {
      top: calc(50% - 4px * var(--device-pixel-ratio));
      right: calc(-8px * var(--device-pixel-ratio));
      transform: rotate(208deg);
    }
  }
  &.top {
    & .arrow {
      top: unset;
      bottom: calc(-6px * var(--device-pixel-ratio));
      transform: rotate(180deg);
    }
  }
  &.right {
    & .arrow {
      top: calc(50% - 4px * var(--device-pixel-ratio));
      left: calc(-8px * var(--device-pixel-ratio));
      transform: rotate(151deg);
      right: unset;
    }
  }
}

@keyframes animateToolTip {
  from {
    opacity: 0;
    visibility: hidden;
  }

  to {
    opacity: 1;
    visibility: visible;
  }
}