.history-round {
    display: flex;
    flex-direction: column;
    gap: 21px;
    padding-left: 20px;
    & > div {
        width: 100%;
    }
}

.history-round-info {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 17px;
    margin-top: calc(6px * var(--device-pixel-ratio));
    justify-content: left;
}

.round-cards {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.round-info-item {
    padding-left: 12px;
    border-left: 1px solid #87879480;
    gap: 9px;
    display: flex;
    flex-direction: column;

    & > div {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: left;
    }
    .title, .time {
        font: normal normal 14px/24px 'SF Pro Text Light';
        font-weight: 300;
        letter-spacing: 0.56px;
        color: #EAEAEC;
        padding: 0;
    }
    .bold-title {
        font: normal normal 14px/24px SF Pro Text Medium;
        letter-spacing: 0.56px;
        color: #EAEAEC;
        text-shadow: 1px 1px 2px #000000;
    }
    .balanc {
        font: normal normal 14px/24px SF Pro Text Medium;
        letter-spacing: 0.56px;
        color: #FFCC00;
        text-shadow: 1px 1px 2px #000000;
    }
}

.history-card-list {
    padding: 12px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    border-radius: 8px;
    margin-right: 20px;
    border: 1px solid rgba(135, 135, 148, 0.5);
    &.active {
        border: 1px solid rgba(255, 204, 0, 0.5);
    }

    & > div:nth-child(1) {
        display: flex;
        flex-direction: row;
    }
    .point {
        text-decoration: underline;
        font: normal normal 14px/16px SF Pro Text bold;
        letter-spacing: 0.56px;
        color: #FFFFFF;
        text-shadow: 1px 1px 2px #000000;
        min-width: 80px;
    }
    .player {
        font: normal normal 14px/16px 'SF Pro Text Light';
        font-weight: 300;
        letter-spacing: 0.56px;
        color: #FFFFFF;
        text-shadow: 1px 1px 2px #000000;
        text-transform: capitalize;
    }
}
.history-round {
    .flip-card.active .flip-card-inner {
        transition-delay: 0.2s;
    }
    .tbl {
        width: 90%;
    }
}

.tbl {
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.row {
    display: flex;
    align-items: center; 
    min-height: 32px;
    height: 32px;
    background: rgba(135, 135, 148, 0.08) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    align-items: center;
    padding-left: 7px;
}
.cell {
    flex: 1;
    height: 100%;
    text-align: left;
    font: normal normal 14px/25px SF Pro Text Medium;
    letter-spacing: 0.56px;
    color: rgba(234, 234, 236, 1);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
}
.cell.span4-5 {
    flex: 8 24px;                    /*  col 4,5 flex-grow/border/padding  */
}
.cell.span3-4 {
    flex: 8 24px;                    /*  col 3,4 flex-grow/border/padding  */
}
.cell.span3-5 {
    flex: 12 36px;                   /*  col 3,4,5 flex-grow/border/padding  */
}
.row:last-child, .row:first-child{
    display: flex;
    justify-content: left;         /*  left horiz. */
    align-items: center;          /*  center vert. */
    min-height: 44px;
    background: rgba(135, 135, 148, 0.16) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    .cell {
        text-align: left;
        font: normal normal 17px/35px SF Pro Text Bold;
        letter-spacing: 0.68px;
        color: rgba(255, 255, 255, 1);
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
        text-transform: capitalize;
    }
}
.row .cell {
    padding: 5px;
    box-sizing: border-box;
}


.smallPc {
    .history-round {
        padding-left: 15px;
        .cards-list {
            gap: 12px;
            font-size: 30px;

            .card {
                width: calc(44px * var(--device-pixel-ratio));
                height: calc(66px * var(--device-pixel-ratio));
                
                .flip-card-back .cardIcon {
                    font-size: calc(69px * var(--device-pixel-ratio));
                    width: calc(44px * var(--device-pixel-ratio));
                    height: calc(66px * var(--device-pixel-ratio));
                }
            }
        }
    }
    .round-info-item {
        gap: 11px;
    }
    .history-card-list {
        margin-right: 16px;
        gap: 8px;
    }
}

.tab {
    .history-round {
        padding-left: 16px;

        .cards-list {
            gap: 12px;

            .card {
                width: calc(44px * var(--device-pixel-ratio));
                height: calc(66px * var(--device-pixel-ratio));

                .flip-card-back .cardIcon {
                    font-size: calc(69px * var(--device-pixel-ratio));
                    width: calc(44px * var(--device-pixel-ratio));
                    height: calc(66px * var(--device-pixel-ratio));
                }
                &:not(:last-child):not(:first-child) {
                    margin-left: 0;
                }
            }

        }
    }
    .history-round {
        gap: 25px;
    }
    .history-card-list{
        gap: 7px;
    }
    .round-info-item {
        gap: 13px;
    }
}

@media only screen and (max-width: 540px) {
    .modal.history {
        .modal-header {
            padding: 12px 27px 7px 16px; 
        }
        .modal-header-title {
            font: normal normal 10px/12px 'SF Pro Text Light';
            font-weight: 300;
            letter-spacing: 0.4px;
        }
        .title, .time {
            font: normal normal 10px/16px 'SF Pro Text Light';
            font-weight: 300;
            letter-spacing: 0.4px;
        }
        .bold-title, .balanc {
            font: normal normal 10px/16px SF Pro Text Bold;
            letter-spacing: 0.4px;
        }

        .history-round {
    
            .cards-list {
                width: 100%;
                gap: 6px;

                .card {
                    width: calc(34px * var(--device-pixel-ratio));
                    height: calc(50px * var(--device-pixel-ratio));
    
                    .flip-card-back .cardIcon {
                        font-size: calc(49px * var(--device-pixel-ratio));
                        width: calc(34px * var(--device-pixel-ratio));
                        height: calc(50px * var(--device-pixel-ratio));
                    }
                    &:not(:last-child):not(:first-child) {
                        margin-left: 0;
                    }
                    .flip-card-inner {
                        width: 100%;
                    }
                }
    
            }
        }
    }

    .row {
        min-height: 20px;
        height: 20px;
    }

    .cell {
        font: normal normal 10px/12px SF Pro Text Medium;
        letter-spacing: 0.4px;
       
    }

    .row:last-child, .row:first-child {
        min-height: 28px;
        height: 28px;
        .cell {
            font: normal normal 10px/17px SF Pro Text Bold;
            letter-spacing: 0.4px;
        }
    }
}