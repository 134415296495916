.bet-board {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
  margin-bottom: calc(27px * var(--device-pixel-ratio));
  width: calc(752px * var(--device-pixel-ratio));
}

.smallPc {
  .bet-board {
    margin-bottom: calc(20px * var(--device-pixel-ratio));
    &.three-d-size {
      margin-bottom: calc(7px * var(--device-pixel-ratio));
    }
  }
}

.tab {
  .bet-board {
    width: 100%;
    position: relative;
    margin: unset;
    margin-bottom: calc(205px * var(--device-pixel-ratio));
    order: 0;
    &.three-d-size {
      margin-bottom: calc(200px * var(--device-pixel-ratio));
    }
  }
}

@media only screen and (max-width: 540px) {
  .bet-board {
    width: 89.3vw;
    margin: 0 auto;
    height: 100%;
    order: 2;
  }
}