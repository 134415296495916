.video-stream {
  width: 100%;
  height: 100%;
  position: absolute !important;
  top: 0;
  right: 0;
  pointer-events: none;
  z-index: 0;
  & #h5live-video-stream {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.tab {
  .video-stream {
    height: calc(480px * var(--device-pixel-ratio));
    top: calc(168 * var(--device-pixel-ratio));
  }
}

@media only screen and (max-width: 540px) {
  .video-stream {
    position: relative !important;
    width: 100vw;
    height: 43.7vh;
    margin-top: 0;
    margin-bottom: 1.4vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    left: unset;
    top: unset;
    right: unset;
  }
}