.board-middle-area {
  display: block;
}
.board-middle-area-768, .board-middle-area-1280, .board-middle-area-mobile{
  display: none;
}
.odd-element{
  display: block;
}
.odd-element-1280, .odd-element-768, .odd-element-mobile {
  display: none;
}
.smallPc {
  .board-middle-area-1280 {
    display: block;
  }
  .board-middle-area-768, .board-middle-area, .board-middle-area-mobile{
    display: none;
  }
  .odd-element, .odd-element-768, .odd-element-mobile {
    display: none;
  }
  .odd-element-1280{
    display: block;
  }
  .fon {
    height: 250px;
    background: transparent linear-gradient(180deg, #1D1B2D00 0%, #1D1B2D1A 26%, #1D1B2D73 50%, #1D1B2DE6 100%) 0% 0% no-repeat padding-box;
  }
  .playerArena {
    margin: 0 calc(0px * var(--device-pixel-ratio)) calc(8px * var(--device-pixel-ratio)) calc(0px * var(--device-pixel-ratio));
    width: calc(100% * var(--device-pixel-ratio));
    & .game-arena {
      margin-left: calc(32px * var(--device-pixel-ratio));
      margin-right: calc(32px * var(--device-pixel-ratio));
    }
  }
  .game-history {
    width: calc(254px * var(--device-pixel-ratio));
    height: calc(111px * var(--device-pixel-ratio));
    overflow: hidden;
    margin-bottom: calc(5px * var(--device-pixel-ratio));
    &-list {
      &-item {
        width: calc(15px * var(--device-pixel-ratio));
        height: calc(15px * var(--device-pixel-ratio));
        margin: calc(1.5px * var(--device-pixel-ratio));
        &.element {
          font-size: calc(10px * var(--device-pixel-ratio));
          line-height: normal;
        }
        &.dot {
          width: calc(16px * var(--device-pixel-ratio));
          height: calc(15.2px * var(--device-pixel-ratio));
        }
      }
    }
    &-header {
      & .list {
        &-item {
          font-size: calc(10px * var(--device-pixel-ratio));
          &:not(:last-child) {
            margin-right: calc(8px * var(--device-pixel-ratio));
          }
          & .group {
            flex-wrap: wrap;
            gap: calc(6px * var(--device-pixel-ratio));
            &-item {
              margin-right: 0;
            }
          }
          & .element {
            width: calc(15px * var(--device-pixel-ratio));
            height: calc(15px * var(--device-pixel-ratio));
            margin-right: calc(2px * var(--device-pixel-ratio));
            font-size: calc(10px * var(--device-pixel-ratio));
            line-height: normal;
          }
        }
      }
      .icon {
        width: calc(10px * var(--device-pixel-ratio));
        height: calc(14px * var(--device-pixel-ratio));
      }
    }
    &-table {
      margin-top: calc(7px * var(--device-pixel-ratio));
      .list {
        margin: calc(1px * var(--device-pixel-ratio));
      }
    }
  }
  .game-history-details {
    width: calc(254px * var(--device-pixel-ratio));
    height: calc(114px * var(--device-pixel-ratio));
  }
  .board {
    width: calc(710px * var(--device-pixel-ratio));
  }
  .bet-board {
    width: calc(678px * var(--device-pixel-ratio));
  }
}

.tab {
  .board-middle-area-768 {
    display: block;
  }
  .board-middle-area-1280, .board-middle-area, .board-middle-area-mobile{
    display: none;
  }
  .odd-element-1280, .odd-element, .odd-element-mobile {
    display: none;
  }
  .odd-element-768 {
    display: block;
  }
  .fon {
    height: 548px;
    background: transparent linear-gradient(180deg, #1D1B2D00 0%, #1D1B2D73 26%, #1D1B2DE6 100%) 0% 0% no-repeat padding-box;
  }
  .game-history {
    width: calc(305px * var(--device-pixel-ratio));
    height: calc(108px * var(--device-pixel-ratio));
    order: 2;
    margin-bottom: calc(44px * var(--device-pixel-ratio));
    margin-left: 10px;
    &-table {
      display: flex;
      flex-direction: row;
      color: #fff;
      overflow: hidden;
      margin-top: calc(4px * var(--device-pixel-ratio));
    }
    &-list {
      &-item {
        width: calc(16px * var(--device-pixel-ratio));
        height: calc(16px * var(--device-pixel-ratio));
        margin: calc(1px * var(--device-pixel-ratio));
        &.element {
          font-size: calc(10px * var(--device-pixel-ratio));
          line-height: calc(12px * var(--device-pixel-ratio));
        }
        &.dot {
          width: calc(16px * var(--device-pixel-ratio));
          height: calc(16px * var(--device-pixel-ratio));
        }
      }
    }
  }
  .game-history-details {
    width: calc(336px * var(--device-pixel-ratio));
    height: calc(119px * var(--device-pixel-ratio));
    order: 3;
    margin-left: auto;
    margin-right: 0;
    margin-top: 29px;
    margin-bottom: 33px;
  }
  .board {
    order: 1;
    width: calc(728px * var(--device-pixel-ratio));
    margin-right: 0;
    margin-left: 0;
    margin-bottom: calc(54px * var(--device-pixel-ratio));
  }
}

@media only screen and (max-width: 540px) {
  .board-middle-area-mobile {
    display: block;
  }
  .board-middle-area-1280, .board-middle-area, .board-middle-area-768{
    display: none;
  }
  .odd-element-1280, .odd-element-768, .odd-element {
    display: none;
  }
  .odd-element-mobile{
    display: block;
  }
  .fon {
    height: 68.065vh;
    background: transparent linear-gradient(180deg, #1D1B2D00 0%, #1D1B2DB3 22%, #1D1B2DE6 100%) 0% 0% no-repeat padding-box;
  }
  .chips-board {
    &.mobile {
      display: block;
    }
    &.desktop {
      display: none;
    }
  }
  .game-history {
    width: 252px;
    height: 16vh;
    order: 2;
    margin-bottom: 0;
    &-list-item {
      width: 16px;
      height: 16px;
      margin: 1px;
      &.dot{
        width: 16px;
        height: 16px;
        &::before{
          width: 6px;
          height: 6px;
          box-shadow: 0 2px 4px black;
          border: 1px solid black
        }
      }
      &.element {
        letter-spacing: 0.48px;
        text-shadow: 1px 1px 2px black;
        box-shadow: 0px 2px 4px black;
        font-size: 10px;
        line-height: 12px;
        &.C, &.A, &.B, &.T, &.B.pair, &.A.pair, &.T.pair {
          border-width: 1px;
        }
      }
    }
    &-header {
      & .list {
        &-item {
          &:not(:last-child) {
            margin-right: 2vw;
          }
          font: 10px/12px "SF Pro Text Medium";
          letter-spacing: 0.4px;
          text-shadow: 1px 1px 2px #000000;
          & .icon {
            width: 3vw;
            height: 3vw;
            margin-right: 1vw;
          }
          & .element {
            width: 16px;
            height: 16px;
            box-shadow: 0px 2px 4px black;
            font: 10px/12px "SF Pro Text Medium";
            letter-spacing: 0.4px;
            text-shadow: 1px 1px 2px black;
            margin-right: 1vw;
            &.C, &.T, &.A, &.B, &.A.pair, &.B.pair {
              border-width: 1px ;
            }
          }
          & .group {
            &-item {
              &:not(:last-child) {
                margin-right: 2.13vw
              }
            }
          }
        }
      }
    }
    &-slider {
      width: 91vw;
      height: 18.488vh;
    }
    &-details {
      width: 265px;
      height: 112px
    }
    &-table {
      margin-top: 0;
      & .list {
        margin-right: 2px;
        & .game-history-table-item {

          &:not(:last-child) {
            margin-bottom: 2px;
          }
        }
        &.versionOne {
          & .element {
            width: 10px;
            height: 10px;
            &:before {
              border-width: 2px;
            }
          }
          & .dot {
            width: 10px;
            height: 10px;
            &:before {
              width: 4px;
              height: 4px;
              border-width: 0;
            }
          }
        }
      }
    }
  }
  .playerArena {

    & .round-arena {
      width: calc(100vw - (2 * 4.5vw));
      flex-wrap: wrap;
    }
  }
  .board {
    width: 100% ;
    margin: 0;
  }
  .tooltip-content {
    display: none;
  }
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px transparent;
    border-radius: 4px;
  }
}

@media only screen and (max-width: 325px) {
  .game-history {
    &-header {
      & .list {
        &-item {
          & .group {
            flex-wrap: wrap;
            width: min-content;
            gap: 0.5vh;
            &-item {
            }
          }
        }
      }
    }
  }
}
