header {
  position: relative;
  z-index: 10;
  height: calc(84px * var(--device-pixel-ratio));
  margin: calc(20px * var(--device-pixel-ratio)) calc(20px * var(--device-pixel-ratio));
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc((100% - 40px * var(--device-pixel-ratio)));
  & .game-controller {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    flex: 1;
    & .info-box {
      width: calc(572px * var(--device-pixel-ratio));
      background: rgba(29, 27, 45, 0.9) 0 0 no-repeat padding-box;
      border: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
      border-radius: calc(8px * var(--device-pixel-ratio));
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: row;
      height: 100%;
      padding: calc(16px * var(--device-pixel-ratio)) calc(32px * var(--device-pixel-ratio));
      & .game-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: calc(16px * var(--device-pixel-ratio));
        width: 100%;
        margin-right: 30px;
        & .title {
          text-align: right;
          font: calc(15px * var(--device-pixel-ratio))/calc(18px * var(--device-pixel-ratio)) "SF Pro Text Medium";
          letter-spacing: calc(0.6px * var(--device-pixel-ratio));
          color: $main-text-color;
          text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) $main-text-shadow-color;
          width: 100%;
        }
        & .description,  & .description span {
          text-align: right;
          font: 300 calc(15px * var(--device-pixel-ratio))/calc(18px * var(--device-pixel-ratio)) "SF Pro Text Medium";
          letter-spacing: calc(0.3px * var(--device-pixel-ratio));
          color: $main-text-color;
          text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) $main-text-shadow-color;
          width: 100%;
          white-space: nowrap;
        }
      }
      & .user-info {
        display: flex;
        flex-direction: column;
        position: relative;
        gap: calc(12px * var(--device-pixel-ratio));
        width: fit-content;
        min-width: calc(166px * var(--device-pixel-ratio));
        &:before{
          content: "";
          width: calc(1px * var(--device-pixel-ratio));
          height: 100%;
          position: absolute;
          top: 0;
          margin-left: calc(-14px * var(--device-pixel-ratio));
          background-color: rgba(135, 135, 148, 0.5);
        }
        &-item {
          display: flex;
          align-items: center;
          & .icon {
            width: calc(30px * var(--device-pixel-ratio));
            height: calc(18px * var(--device-pixel-ratio));
            margin-right: calc(2px * var(--device-pixel-ratio));
          }
          & .title {
            text-align: left;
            font: calc(15px * var(--device-pixel-ratio))/calc(18px * var(--device-pixel-ratio)) "SF Pro Text Bold";
            letter-spacing: calc(0.6px * var(--device-pixel-ratio));
            color: rgba(255, 255, 255, 1);
            text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) rgba(0, 0, 0, 1);
            min-width: calc(72px * var(--device-pixel-ratio));
            margin-right: calc(20px * var(--device-pixel-ratio));
          }
          & .description {
            text-align: left;
            font: 900 calc(15px * var(--device-pixel-ratio))/calc(18px * var(--device-pixel-ratio)) "SF Pro Text Light";
            letter-spacing: calc(0.6px * var(--device-pixel-ratio));
            color: rgba(255, 204, 0, 1);
            text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) rgba(0, 0, 0, 1);
            text-transform: uppercase;
            white-space: nowrap;
            width: calc(41px * var(--device-pixel-ratio));
          }
          &.total-bets {
            height: calc(22px * var(--device-pixel-ratio));
            & .icon {
              width: calc(28px * var(--device-pixel-ratio));
              height: calc(22px * var(--device-pixel-ratio));
              margin-right: calc(4px * var(--device-pixel-ratio));
            }
          }
        }
      }
    }
  }
}

.smallPc {
  header {
    width: calc(100% - 32px * var(--device-pixel-ratio));
    height: calc(64px * var(--device-pixel-ratio));
    margin: calc(16px * var(--device-pixel-ratio));
    & .game-controller {
      & .info-box {
        max-width: calc(456px * var(--device-pixel-ratio));
        padding: calc(11px * var(--device-pixel-ratio)) calc(24px * var(--device-pixel-ratio));
        & .game-info {
          gap: calc(12px * var(--device-pixel-ratio));
          margin-right: 25px;
          & .title,& .description, & .description span {
            font-size: calc(12px * var(--device-pixel-ratio));
            line-height: calc(14px * var(--device-pixel-ratio));
            letter-spacing: calc(0.48px * var(--device-pixel-ratio));
          }
        }
        & .user-info {
          gap: calc(12px * var(--device-pixel-ratio));
          min-width: calc(133px * var(--device-pixel-ratio));
          &:before{
            margin-left: calc(-12px * var(--device-pixel-ratio));
          }
          &-item {
            height:  calc(14px * var(--device-pixel-ratio));
            & .icon {
              width: calc(22px * var(--device-pixel-ratio));
              height: calc(21px * var(--device-pixel-ratio));
              margin-right: calc(6px * var(--device-pixel-ratio));
            }
            & .title {
              font-size: calc(12px * var(--device-pixel-ratio));
              min-width: calc(57px * var(--device-pixel-ratio));
              margin-right: calc(16px * var(--device-pixel-ratio));
              letter-spacing: calc(0.4px * var(--device-pixel-ratio));
            }
            & .description {
              font-size: calc(12px * var(--device-pixel-ratio));
              letter-spacing: calc(0.4px * var(--device-pixel-ratio));
              width: calc(32px * var(--device-pixel-ratio));
            }
            &.total-bets {
              height: calc(14px * var(--device-pixel-ratio));
            }
          }

        }
      }
    }
  }
}

.tab {
  header {
    height: calc(128px * var(--device-pixel-ratio));
    width: calc(100% - calc(30px * var(--device-pixel-ratio)));
    margin: calc(15px * var(--device-pixel-ratio)) calc(15px * var(--device-pixel-ratio));
    & .game-controller {
      & .info-box {
        border-radius: calc(8px * var(--device-pixel-ratio));
        width: calc(294px * var(--device-pixel-ratio));
        height: calc(128px * var(--device-pixel-ratio));
        flex-wrap: wrap;
        padding: calc(12px * var(--device-pixel-ratio));
        padding-left: 0px;
        padding-right: 0px;
        & .game-info {
          padding: 0 0 0 calc(22px * var(--device-pixel-ratio));
          margin-right: 0;
          margin-bottom: calc(20px * var(--device-pixel-ratio));
          width: 100%;
          gap: calc(11px * var(--device-pixel-ratio));
          & .title {
            text-align: left;
            font-size: calc(12px * var(--device-pixel-ratio));
            line-height: calc(14px * var(--device-pixel-ratio));
            letter-spacing: calc(0.5px * var(--device-pixel-ratio));
          }
          & .description {
            text-align: left;
            font-size: calc(12px * var(--device-pixel-ratio));
            line-height: calc(14px * var(--device-pixel-ratio));
            letter-spacing: calc(0.4px * var(--device-pixel-ratio));
            span {
              font-size: calc(12px * var(--device-pixel-ratio));
              line-height: 14px;
              letter-spacing: 0.48px;
              font-family: "SF Pro Text Light";
              font-weight: 300;
            }
          }
        }
        & .user-info {
          padding: 0 0 0 calc(21px * var(--device-pixel-ratio));
          width: 100%;
          gap: calc(5px * var(--device-pixel-ratio));
          &:before{
            left: 8.4%;
            top: calc(-9px * var(--device-pixel-ratio));
            width: 81.8%;
            height: calc(1px * var(--device-pixel-ratio));
            margin-left: 0;
          }
          & .icon {
            width: calc(21px * var(--device-pixel-ratio));
            height: calc(21px * var(--device-pixel-ratio));
            margin-right: calc(6px * var(--device-pixel-ratio));
          }
          & .title {
            text-align: left;
            font-size: calc(12px * var(--device-pixel-ratio));
            min-width: calc(57px * var(--device-pixel-ratio));
            margin-right: calc(15px * var(--device-pixel-ratio));
            line-height: calc(14px * var(--device-pixel-ratio));
            letter-spacing: calc(0.8px * var(--device-pixel-ratio));
          }
          & .description {
            text-align: left;
            font-size: calc(12px * var(--device-pixel-ratio));
            line-height: calc(14px * var(--device-pixel-ratio));
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 540px) {
  header {
    height: 67px;
    padding: 8px 16px;
    margin: 6px 0 0 0;
    width: 100vw;
    background: #1D1B2DB3 0% 0% no-repeat padding-box;
    border: 1px solid #87879480;
    border-right-color: transparent;
    border-left-color: transparent;
    & .game-controller {
      height: auto;
      & .info-box {
        background: transparent;
        border: 0;
        flex-wrap: nowrap;
        padding: 0;
        gap: 5vw;
        max-height: 100%;
        width: calc(100% - 32px);
        max-width: 65vw;
        align-items: center;

        & .game-info {
          white-space: nowrap;
          padding: 0;
          margin: 0;
          gap: 8px;
          min-width: 42vw;
          & .title {
            letter-spacing: 0.4px;
            font: 2.66vw/12px "SF Pro Text Medium";
            font-size: min(2.66vw, 10px);
            text-shadow: 1px 1px 2px #000000;

            max-width: 41.06vw;
            height: 30px;
            white-space: break-spaces;
            text-align: center;
            line-height: 15px;
          }
          & .description {
            font-size: min(2.66vw, 10px);
            line-height: 12px;
            letter-spacing: 0.35px;
            text-align: left;
            span {
              font-size: min(2.66vw, 10px);
              line-height: 12px;
              letter-spacing: 0.35px;
            }
          }
        }
        & .user-info {
          top: -28%;
          width: 1px;
          height: 155%;
          left: -2vw;
          margin-left: 0px;
          gap: calc(8px * var(--device-pixel-ratio));

          &:before {
            top: -1vh;
            width: 1px;
            height: 134%;
            left: -2.4vw;
            margin-left: 0px;
          }
          &-item {
            align-items: flex-end;
            & .icon {
              width: 18px;
              height: 12px;
              margin-right: 1vw;
              margin-left: 1vw;
              position: relative;
              top: 0;
            }
            &.total-bets{
              height: 12px;
              & .icon {
                width: 18px;
                height: 12px;
                margin-right: 1vw;
                margin-left: 1vw;
                position: relative;
                top: 0;
              }
            }
            & .title {
              font-size: min(2.66vw, 10px);
              line-height: 12px;
              margin-right: 2.133vw;
              min-width: 12.8vw;
              letter-spacing: 0.4px;
            }
            & .description {
              font-size: min(2.66vw, 10px);
              line-height: 12px;
              letter-spacing: 0.4px;
            }
          }
        }
      }
      & .settings-box {
        width: 32px;
        z-index: 1;
      }
    }
  }
}