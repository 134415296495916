.popup-btn {
  background: $infoPopUp-btn-bg-color 0% 0% no-repeat padding-box;
  border: calc(1px * var(--device-pixel-ratio)) solid $infoPopUp-btn-border-color;
  border-radius: calc(18px * var(--device-pixel-ratio));
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(8px * var(--device-pixel-ratio)) calc(41px * var(--device-pixel-ratio));
  font: 900 calc(17px * var(--device-pixel-ratio))/calc(20px * var(--device-pixel-ratio)) "SF Pro Text Light";
  letter-spacing: calc(0.68px * var(--device-pixel-ratio));
  color: $infoPopUp-btn-color;
  text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) $infoPopUp-btn-text-shadow-color;
  cursor: pointer;
  &.active {
    color: $infoPopUp-btn-active-text-color;
  }
  &:hover {
    background: #54687E66 0% 0% no-repeat padding-box;
    border: 1px solid #87879480;
  }
  &:active {
    background: #54687EE6 0% 0% no-repeat padding-box;
    border: 1px solid #878794;
  }
}

.btn_1 {
  background: #1D1B2DE6 0 0 no-repeat padding-box;
  border: calc(1px * var(--device-pixel-ratio)) solid #87879480;
  border-radius: calc(37px * var(--device-pixel-ratio));
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  padding: calc(16px * var(--device-pixel-ratio)) calc(32px * var(--device-pixel-ratio));
  font: 900 calc(17px * var(--device-pixel-ratio))/calc(20px * var(--device-pixel-ratio)) "SF Pro Text Light";
  letter-spacing: calc(0.68px * var(--device-pixel-ratio));
  color: #FFCC00;
  text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) $infoPopUp-btn-text-shadow-color;
  cursor: pointer;
  &:hover {
    background: #54687E66 0% 0% no-repeat padding-box;
  }
  &:active {
    background: #54687EE6 0% 0% no-repeat padding-box;
  }
}

.circled-button {
  width: calc(32px * var(--device-pixel-ratio));
  height: calc(32px * var(--device-pixel-ratio));
  border-radius: 100%;
  background: rgba(29, 27, 45, 0.9) 0 0 no-repeat padding-box;
  border: 1px solid rgba(135, 135, 148, 0.5);
  cursor: pointer;
  &:hover {
    background: rgba(84, 104, 126, 0.4) 0% 0% no-repeat padding-box;
    border: 1px solid rgba(135, 135, 148, 0.5);
  }
  &:active, &.active {
    background: rgba(84, 104, 126, 0.9) 0% 0% no-repeat padding-box;
    border: 1px solid rgba(135, 135, 148, 1);
  }
  &.small {
    width: calc(24px * var(--device-pixel-ratio));
    height: calc(24px * var(--device-pixel-ratio));
  }
}

@media only screen and (max-width: 540px) {
  .btn_1 {
    border: 1px  solid #87879480;
    border-radius: 24px;
    padding: 16px  32px ;
    font: 900 14px/16px "SF Pro Text Light";
    letter-spacing: 0.56px;
    text-shadow:1px 1px 2px $infoPopUp-btn-text-shadow-color;
  }
}