.user-actions-area {
  position: absolute;
  pointer-events: none;
  .board-body {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    .left, .right, .middle {
      
    }
    .left, .right {
      width: 108px;
      height: 108px;
    }
    .middle {
      width: 128px;
      height: 128px;
      .chips-list {
        top: 29px;
        left: 29px;
      }
    }
    .left {
      .chips-list {
        top: 19px;
        left: 19px;
      }
    }
    .right {
      .chips-list {
        top: 19px;
        left: 19px;
      }
    }
  }
  &.threeD {
    .board-body {
      
      
    }
  }
}

.smallPc {
  .user-actions-area {
    width: calc(672px* var(--device-pixel-ratio));
    .board-body {
      gap: 32px;
      .left, .right {
        width: 98px;
        height: 98px;
      }
      .middle {
        width: 118px;
        height: 118px;
        .chips-list {
          top: 29px;
          left: 29px;
        }
      }
      .left {
        .chips-list {
          top: 19px;
          left: 19px;
        }
      }
      .right {
        .chips-list {
          top: 19px;
          left: 19px;
        }
      }
    }
    &.threeD {
      .board-body {
        
        
      }
    }
  }
}

.tab {
  .user-actions-area {
    width: calc(672px* var(--device-pixel-ratio));
    .board-body {
      gap: 32px;
      align-items: flex-start;
      .left, .right {
        width: 98px;
        height: 98px;
      }
      .middle {
        width: 118px;
        height: 118px;
        .chips-list {
          top: 30px;
          left: 30px;
        }
      }
      .left {
        .chips-list {
          top: 30px;
          left: 19px;
        }
      }
      .right {
        .chips-list {
          top: 30px;
          left: 21px;
        }
      }
    }
    &.threeD {
      .board-body {
        .middle {
          .chips-list {
            top: 43px;
            left: 30px;
          }
        }
        .left {
          .chips-list {
            top: 43px;
            left: 24px;
          }
        }
        .right {
          .chips-list {
            top: 43px;
            left: 17px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 540px) {
  .user-actions-area {
    width: 89.6vw;
    height: 100%;
    max-width: 50.37vh;
    .board-body {
      gap: 5.333vw;
      align-items: flex-start;
      .left, .right {
        width: 20.8vw;
        height: 20.8vw;
      }
      .middle {
        width: 26.133vw;
        height: 26.133vw;
        .chips-list {
          top: 30px;
          left: 30px;
        }
      }
      .left {
        .chips-list {
          top: 30px;
          left: 19px;
        }
      }
      .right {
        .chips-list {
          top: 30px;
          left: 21px;
        }
      }
    }
  }
}