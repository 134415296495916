.game-history {
  width: calc(447px * var(--device-pixel-ratio));
  height: calc(145px * var(--device-pixel-ratio));
  overflow: hidden;
  margin-bottom: calc(5px * var(--device-pixel-ratio));
  &-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    &-item {
      width: calc(20px * var(--device-pixel-ratio));
      height: calc(20px * var(--device-pixel-ratio));
      margin: calc(2px * var(--device-pixel-ratio));
      margin-bottom: calc(3px * var(--device-pixel-ratio));
      margin-right: calc(2.8px * var(--device-pixel-ratio));
      &.element {
        // box-shadow: 0px calc(2px * var(--device-pixel-ratio)) calc(4px * var(--device-pixel-ratio)) rgba(0, 0, 0, 1);
        display: flex;
        justify-content: center;
        align-items: center;
        font: calc(12px * var(--device-pixel-ratio)) "SF Pro Text Medium";
        letter-spacing: calc(0.48px * var(--device-pixel-ratio));
        color: rgba(255, 255, 255, 1);
        text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) 2px rgba(0, 0, 0, 1);
        border-radius: 100%;
        &.B {
          background: $history-B-color 0 0 no-repeat padding-box;
        }
        // &.A , &.B {
        //   &.pair {
        //     background: $history-Pair-color 0 0 no-repeat padding-box;
        //   }
        // }
        &.A   {
          background: $history-A-color 0 0 no-repeat padding-box;
        }
        &.T {
          background: $history-T-color 0% 0% no-repeat padding-box;
        }
      }
      &.dot {
        width: calc(20px * var(--device-pixel-ratio));
        height: calc(20px * var(--device-pixel-ratio));
        position: relative;
        &:before {
          content: "";
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: calc(6px * var(--device-pixel-ratio));
          height: calc(6px * var(--device-pixel-ratio));
          border-radius: 100%;
          background: rgba(135, 135, 148, 1) 0 0 no-repeat padding-box;
          box-shadow: 0 calc(2px * var(--device-pixel-ratio)) calc(4px * var(--device-pixel-ratio)) rgba(0, 0, 0, 1);
          border: calc(1px * var(--device-pixel-ratio)) solid rgba(0, 0, 0, 1);
          opacity: 0.7;
        }
      }
    }
  }
  &-header {
    & .list {
      display: flex;
      list-style: none;
      justify-content: flex-start;
      align-items: center;
      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: left;
        font: calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio))"SF Pro Text Medium";
        letter-spacing: calc(0.48px * var(--device-pixel-ratio));
        color: #FFFFFF;
        text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) #000000;
        & .group {
          display: flex;
          align-items: center;
          &-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            &:not(:last-child) {
              margin-right: calc(10px * var(--device-pixel-ratio));
            }
          }
        }
        & .element {
          width: calc(19px * var(--device-pixel-ratio));
          height: calc(19px * var(--device-pixel-ratio));
          box-shadow: 0px calc(2px * var(--device-pixel-ratio)) calc(4px * var(--device-pixel-ratio)) rgba(0, 0, 0, 1);
          display: flex;
          justify-content: center;
          align-items: center;
          font: calc(12px * var(--device-pixel-ratio)) "SF Pro Text Medium";
          letter-spacing: calc(0.48px * var(--device-pixel-ratio));
          color: rgba(255, 255, 255, 1);
          text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) 2px rgba(0, 0, 0, 1);
          border-radius: 100%;
          margin-right: calc(4px * var(--device-pixel-ratio));
          &.B {
            background: $history-B-color 0 0 no-repeat padding-box;
          }
          // &.A , &.B {
          //   &.pair {
          //     background: $history-Pair-color 0 0 no-repeat padding-box;
          //     border: calc(1px * var(--device-pixel-ratio)) solid $history-Pair-border-color;
          //   }
          // }
          &.A   {
            background: $history-A-color 0 0 no-repeat padding-box;
          }
          &.T {
            background: $history-T-color 0% 0% no-repeat padding-box;
          }
        }
        & .icon {
          width: calc(12px * var(--device-pixel-ratio));
          height: calc(14px * var(--device-pixel-ratio));
          margin-right: calc(4px * var(--device-pixel-ratio));
          & svg {
            width: 100%;
            height: 100%;
          }
        }
        &:not(:last-child) {
          margin-right: calc(4px * var(--device-pixel-ratio));
        }
      }
    }
  }
  &-table {
    display: flex;
    flex-direction: row;
    color: #fff;
    overflow: hidden;
    margin-top: calc(14px * var(--device-pixel-ratio));
    & .list {
      display: flex;
      flex-direction: column;
      margin: calc(2px * var(--device-pixel-ratio));
      &.versionOne, &.versionTwo, &.versionThree {
        & .element {
          width: calc(12px * var(--device-pixel-ratio));
          height: calc(12px * var(--device-pixel-ratio));
          position: relative;
        }
      }
      &.versionOne {
        & .element {
          &:before {
            content: "";
            width: 70%;
            height: 70%;
            border-radius: 100%;
            border: calc(2px * var(--device-pixel-ratio)) solid;
            position: absolute;
            left: 0;
            top: 0;
          }
          &.B {
            &:before {
              border-color: $history-B-color;
            }
          }
          &.A {
            &:before {
              border-color: $history-A-color;
            }

          }
          &.T {
            &:before {
              border-color: $history-T-color;
            }
          }
        }
      }
      &.versionTwo {
        & .element {
          &:before {
            content: "";
            width: 100%;
            height: 100%;
            border-radius: 100%;
            border: calc(2px * var(--device-pixel-ratio)) solid;
            position: absolute;
            left: 0;
            top: 0;
          }
          &.C {
            &:before {
              background-color: rgba(250, 169, 186, 1);
            }
          }
          &.A , &.B {
            &:before {
              background-color: #72BBEF;
            }
            &.pair {
              &:before {
                background-color: rgba(255, 255, 255, 1);
              }
            }
          }
          &.T {
            &:before {
              background-color: rgba(124, 239, 114, 1);
            }
          }
        }
      }
      &.versionThree {
        & .element {
          &:before {
            content: "";
            width: 100%;
            height: calc(1px * var(--device-pixel-ratio));
            position: absolute;
            left: 0;
            top: 0;
            transform: rotate(45deg);
          }
          &.C {
            &:before {
              background-color: rgba(250, 169, 186, 1);
            }
          }
          &.A , &.B {
            &:before {
              background-color: #72BBEF;
            }
            &.pair {
              &:before {
                background-color: rgba(255, 255, 255, 1);
              }
            }
          }
          &.T {
            &:before {
              background-color: rgba(124, 239, 114, 1);
            }
          }
        }
      }
    }
    &-item {
      &:not(:last-child) {
        margin-bottom: calc(4px * var(--device-pixel-ratio));
      }
      &.dot {
        width: calc(12px * var(--device-pixel-ratio));
        height: calc(12px * var(--device-pixel-ratio));
        position: relative;
        &:before {
          content: "";
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: calc(4px * var(--device-pixel-ratio));
          height: calc(4px * var(--device-pixel-ratio));
          border-radius: 100%;
          background: rgba(135, 135, 148, 1) 0 0 no-repeat padding-box;
          // box-shadow: 0 calc(2px * var(--device-pixel-ratio)) calc(4px * var(--device-pixel-ratio)) rgba(0, 0, 0, 1);
          border: calc(1px * var(--device-pixel-ratio)) solid rgba(0, 0, 0, 1);
          opacity: 0.7;
        }
      }
    }
  }
}