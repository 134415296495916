.template-button-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  &.mobile-icon {
    display: none;
  }
  & .template-button-item {
    width: calc(48px * var(--device-pixel-ratio));
    height: calc(48px * var(--device-pixel-ratio));
    border-radius: 50%;
    background: rgba(29, 27, 45, 0.9) 0 0 no-repeat padding-box;
    border: 1px solid rgba(135, 135, 148, 0.5);

    &:not(:first-child) {
      margin-left: calc(24px * var(--device-pixel-ratio));
    }

    cursor: pointer;

    &:hover {
      background: rgba(84, 104, 126, 0.4) 0 0 no-repeat padding-box;
      border: 1px solid rgba(135, 135, 148, 0.5);
    }

    &:active, &.active {
      background: rgba(84, 104, 126, 0.9) 0 0 no-repeat padding-box;
      border: 1px solid rgba(135, 135, 148, 1);
    }

    &.disabled {
      user-select: none;
      pointer-events: none;
      opacity: 0.4;

      & .disabled {
        opacity: 0.4;
      }
    }
  }

  &.delete-bets {
    &.mobile {
      display: none;
    }
  }
}

.smallPc {
  .template-button-list {
    & .template-button-item {
      width: calc(38px * var(--device-pixel-ratio));
      height: calc(38px * var(--device-pixel-ratio));

      &:not(:first-child) {
        margin-left: calc(16px * var(--device-pixel-ratio));
      }
    }
  }
}


.tab {
  .template-button-list {
    & .template-button-item {
      width: calc(38px * var(--device-pixel-ratio));
      height: calc(38px * var(--device-pixel-ratio));

      &:not(:first-child) {
        margin-left: calc(20px * var(--device-pixel-ratio));
      }
    }
  }
}

@media only screen and (max-width: 540px) {
  .template-button-list {
    &.delete-bets {
      &.mobile {
        display: flex;
        width: fit-content;
        margin-top: 4.04vh;
        & .template-button-item {
          width: 9.53vw;
          height: 9.53vw;
          max-width: 5.797vh;
          max-height: 5.797vh;
          border: 1px solid #87879480;

          svg {
            transform: scale(1.29);
          }
        }
      }
    }
    &.mobile-icon {
      display: flex;
    }

    &.desktop-icon {
      display: none;
    }

    & .template-button-item {
      width: 32px;
      height: 32px;
      border: none;

      & .tooltip-wrapper {
        display: flex;
        align-items: center;

        svg {
          height: 100%;
          width: 100%;
          margin: auto;
        }
      }
    }
  }
}
