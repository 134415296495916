.game-history-details {
  width: calc(417px * var(--device-pixel-ratio));
  height: calc(150px * var(--device-pixel-ratio));
  overflow: hidden;
  &-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: calc(16px * var(--device-pixel-ratio));
  }
}