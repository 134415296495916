.mainContent {
  background: transparent linear-gradient(180deg, #0E0506 0%, #100A0F 100%) 0% 0% no-repeat padding-box;
}

.playerArena {
  width: calc(100% - 45px * var(--device-pixel-ratio));
  height: fit-content;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 calc(24px * var(--device-pixel-ratio)) calc(12px * var(--device-pixel-ratio)) calc(19px * var(--device-pixel-ratio));
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  & .game-arena {
    position: relative;
  }
}

.tab {
  .playerArena {
    height: auto;
    margin: 0 calc(20px * var(--device-pixel-ratio)) calc(20px * var(--device-pixel-ratio)) calc(20px * var(--device-pixel-ratio));
    width: calc(100% - calc(40px * var(--device-pixel-ratio)));
    z-index: 1;
    flex-wrap: wrap;
    & .game-arena {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      &.d3 {
        width: calc(719px * var(--device-pixel-ratio));
      }
    }
  }
}
@media only screen and (max-width: 540px) {
  .mainContent {
    background: #000 linear-gradient(180deg, #1D1B2D00 0%, #1D1B2DB3 22%, #1D1B2DE6 100%) 0% 0% no-repeat padding-box
  }
  .playerArena {
    position: relative;
    top: 0;
    z-index: 1;
    max-height: unset;
    bottom: unset;
    width: 100%;
    height: fit-content;
    margin: auto;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    & .game-arena {
      height: fit-content;
      width: 100vw;
      margin: 0;
      display: flex;
      margin-bottom: 1.199vh;
      flex-direction: column;
      align-items: center;
    }
  }
}