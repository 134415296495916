.cards {
  pointer-events: none;
  position: absolute;
  top: calc(50px * var(--device-pixel-ratio));
  width: calc(1136px * var(--device-pixel-ratio));
  left: 50%;
  transform: translateX(-50%);
  transition: top 0.45s;
  &.threeD {
    top: calc(20px * var(--device-pixel-ratio));
  }
  &-body {
    display: flex;
    height: calc(64px * var(--device-pixel-ratio));
    padding-top: calc(3px * var(--device-pixel-ratio));
    justify-content: space-between;
    width: 100%;
  }

  &-list {
    display: flex;
    flex-direction: row;
    gap: 12px;
    &.revers {
      flex-direction: row-reverse;
      & .card {
        &:last-child {
          margin-right: calc(25px * var(--device-pixel-ratio));
          margin-left: 0;
        }
        &:not(:last-child):not(:first-child) {
          margin-right: calc(12px * var(--device-pixel-ratio));
          margin-left: 0;
        }
      }
    }

    & .card {
      display: flex;
      width: calc(44px * var(--device-pixel-ratio));
      height: calc(69px * var(--device-pixel-ratio));

      & .flip-card {
        width: 100%;
        height: 100%;
        perspective: calc(1000px * var(--device-pixel-ratio));
      }

      & .flip-card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        transition: transform 0.6s;
        transform-style: preserve-3d;
      }

      & .flip-card.active .flip-card-inner {
        transform: rotateY(180deg);
      }

      & .flip-card-front, .flip-card-back {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
      }

      & .flip-card-front, & .flip-card-back {
        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transform: scale(1.04) translateY(2.5%);
        }
      }

      & .flip-card-back {
        transform: rotateY(180deg);

        & .cardIcon {
          height: 100%;
          font-size: calc(64px * var(--device-pixel-ratio));
          position: absolute;
          top: 0;
          box-shadow: none;
          background: transparent;
        }
      }
    }
  }
}


.smallPc {
  .cards {
    width: calc(972px * var(--device-pixel-ratio));
    top: calc(86px * var(--device-pixel-ratio));

    &-body {
      height: calc(50px * var(--device-pixel-ratio));
    }
    &-list {
      gap: 8px;
      &.revers {
        flex-direction: row-reverse;
        & .card {
          &:last-child {
            margin-right: calc(16px * var(--device-pixel-ratio));
            margin-left: 0;
          }
          &:not(:last-child):not(:first-child) {
            margin-right: calc(8px * var(--device-pixel-ratio));
            margin-left: 0;
          }
        }
      }

      & .card {
        width: calc(34px * var(--device-pixel-ratio));
        height: calc(50px * var(--device-pixel-ratio));

        & .flip-card-back {
          & .cardIcon {
            font-size: calc(50px * var(--device-pixel-ratio));
          }
        }
        & .flip-card-front {
          & img {
            transform: scale(1.14) translateY(2%);
          }
        }
      }
    }
    &.threeD {
      top: calc(70px * var(--device-pixel-ratio));
      &-list {
        & .card {
          width: calc(36px * var(--device-pixel-ratio));
          height: calc(56px * var(--device-pixel-ratio));
        }
      }
    }
  }
}

.tab {
  .cards {
    position: unset;
    width: calc(524px * var(--device-pixel-ratio));
    transform: unset;
    margin: auto;
    margin-bottom: calc(4px * var(--device-pixel-ratio));
    order: 0;
    &-body {
      height: calc(50px * var(--device-pixel-ratio));
      margin-bottom: calc(24px * var(--device-pixel-ratio));
    }
    &-list {
      gap: 8px;
      width: calc(294px * var(--device-pixel-ratio));
      &.revers {
        & .card {
          &:last-child {
            margin-right: calc(17px * var(--device-pixel-ratio));
            margin-left: 0;
          }
          &:not(:last-child):not(:first-child) {
            margin-right: calc(8px * var(--device-pixel-ratio));
            margin-left: 0;
          }
        }
      }

      & .card {
        display: flex;
        width: calc(33px * var(--device-pixel-ratio));
        height: calc(53px * var(--device-pixel-ratio));

        & .flip-card-back {
          & .cardIcon {
            font-size: calc(52px * var(--device-pixel-ratio))
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 540px) {
  .cards {
    position: absolute;
    top: -6vh;
    transform: translateX(-50%);
    margin: 0;
    order: 0;
    left: 50%;
    width: 100vw;
    &.threeD {
      top: -6vh;
    }
    &-body {
      height: 45px;
    }
    &-list {
      width: 38vw;
      gap: 0;
      &.revers {
        & .card {
          &:last-child {
            margin-right: 11px;
            margin-left: 0;
          }
          &:not(:last-child):not(:first-child) {
            margin-right: 6px;
            margin-left: 0;
          }
        }
      }

      & .card {
        display: flex;
        width: 21px;
        height: 41px;

        & .flip-card-back {
          & .cardIcon {
            height: 40px;
            font-size: 42px;
          }
        }
        & .flip-card-inner {
          width: 28px;
          & .cardIcon {

          }
        }
      }
    }
  }
}

@media only screen and (max-width: 316px) {
  .cards {
    &-list {
      & .card {
        width: 5.9vw;
      }
    }
  }
}